<template>
  <div>
    <the-client-layout-header headline="Dashboard" />

    <v-row class="d-flex ma-2" justify="center">
      <v-col cols="12" sm="6" md="4" lg="4"
        ><client-dashboard-networth />
      </v-col>
      <v-col cols="12" sm="6" md="5" lg="4"
        ><client-dashboard-income-statement />
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="4">
        <client-dashboard-risk-tolerance />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import TheClientLayoutHeader from '@/components/TheClientLayoutHeader.vue'
import ClientDashboardRiskTolerance from '@/components/ClientDashboardRiskTolerance.vue'
import ClientDashboardIncomeStatement from '@/components/ClientDashboardIncomeStatement.vue'
import ClientDashboardNetworth from '@/components/ClientDashboardNetworth.vue'

export default {
  name: 'clientDashboard',

  beforeRouteEnter(routeTo, routeFrom, next) {
    // making sure, when navigating to this page, the slider allways moves to "DASHBOARD" ( tab = 0 )
    store.commit('SET_CLIENT_LAYOUT_TAB', 0)
    next()
  },

  components: {
    TheClientLayoutHeader,
    ClientDashboardRiskTolerance,
    ClientDashboardNetworth,
    ClientDashboardIncomeStatement
  },

  data() {
    return {}
  },

  computed: {
    ...mapState(['client', 'clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
