<template>
  <div>
    <v-card v-if="clientLedger.ledger.conditions.riskType" outlined>
      <v-card-title class="overline">
        Risk Type
      </v-card-title>
      <v-row class="mt-n8">
        <v-col cols="12" class="text-left">
          <v-card-text class="headline"
            ><v-btn
              text
              retain-focus-on-click
              color="success"
              :to="{ name: 'clientProfile', params: { tabs: 'risktype' } }"
              class="subtitle-1 font-weight-medium"
              >{{ clientLedger.ledger.conditions.riskType }}</v-btn
            >
          </v-card-text>
          <v-card-text class="subtitle-1 ml-5 mt-n9">{{
            riskToleranceData.text
          }}</v-card-text>
        </v-col>
      </v-row>
      <v-row
        v-if="clientLedger.ledger.conditions.modelPortfolio.modelPortfolio"
        class="mb-2 mt-n1"
      >
        <v-col cols="12" class="text-left ml-5 mt-n6">
          <apexchart
            type="donut"
            width="250"
            height="250"
            :options="riskToleranceData.chartOptions"
            :series="riskToleranceData.series"
          ></apexchart>
        </v-col>
      </v-row>
    </v-card>

    <v-card v-else class="mx-auto" outlined>
      <v-list-item three-line>
        <v-list-item-content class="overline mb-4">
          Risk Tolerance
        </v-list-item-content>
      </v-list-item>
      <v-card-text class="subtitle-1 primary--text text-left mt-n8">
        Choose a Risk Type
        <v-btn
          text
          color="success"
          class="subtitle-1 text-lowercase mx-n4 mb-1"
          @click="dialogSelect = !dialogSelect"
          >here</v-btn
        >
        or find out by using this
        <v-btn
          text
          color="success"
          class="subtitle-1 text-capitalize mx-n4 mb-1"
          @click="dialogQuestionaire = !dialogQuestionaire"
          >Questionaire</v-btn
        >
      </v-card-text>
    </v-card>

    <DialogRiskTypeSelect
      :selectedData="selectedData"
      :visible="dialogSelect"
      @close="onCloseDialogSelect"
    />

    <DialogRiskTypeQuestionaire
      :selectedData="selectedData"
      :visible="dialogQuestionaire"
      @close="onCloseDialogQuestionaire"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import DialogRiskTypeSelect from '@/components/dialogs/DialogRiskTypeSelect.vue'
import DialogRiskTypeQuestionaire from '@/components/dialogs/DialogRiskTypeQuestionaire.vue'

export default {
  name: 'clientDashboardRiskTolerance',

  components: {
    DialogRiskTypeSelect,
    DialogRiskTypeQuestionaire
  },

  data() {
    return {
      dialogSelect: false,
      dialogQuestionaire: false
    }
  },

  methods: {
    onCloseDialogSelect() {
      this.dialogSelect = false
    },
    onCloseDialogQuestionaire() {
      this.dialogQuestionaire = false
    }
  },

  computed: {
    riskToleranceData() {
      let data = {
        chartOptions: {},
        series: [],
        labels: ['Income Assets', 'Growth Assets'],
        text: ''
      }
      // this.clientLedger.conditions.modelPortfolio contains the selected modelPortfolio object
      let sumIncome = 0
      let sumGrowth = 0
      Object.entries(
        this.clientLedger.ledger.conditions.modelPortfolio.data || []
      ).forEach(([, obj]) => {
        if (obj.type == 'Income') {
          sumIncome += obj.y
        } else {
          sumGrowth += obj.y
        }
      })
      data.series.push(sumIncome)
      data.series.push(sumGrowth)
      if (sumIncome == 0 && sumGrowth == 0) {
        data.text = ''
      } else {
        data.text = sumIncome + '% Income / ' + sumGrowth + '% Growth'
      }
      data.chartOptions = {
        colors: ['#31465F', '#FF9900'],
        labels: data.labels,
        dataLabels: {
          enabled: false
        },
        yaxis: {
          type: 'numeric',
          labels: {
            formatter: function(value) {
              return value + ' %'
            }
          }
        },
        legend: {
          show: true,
          showForNullSeries: true,
          position: 'right',

          offsetY: -5,
          offsetX: 0
        }
      }

      return data
    },

    selectedData() {
      let data = {
        chartOptions: {},
        series: [],
        modelPortfolioNames: [],
        modelPortfolio: {}
      }
      Object.entries(this.advisorDefinitionsModelPortfolios).forEach(
        ([, val]) => {
          if (this.clientLedger.ledger.conditions.riskType == val.riskType) {
            data.modelPortfolioNames.push(val.modelPortfolio)
          }
          if (
            this.clientLedger.ledger.conditions.riskType == val.riskType &&
            this.clientLedger.ledger.conditions.modelPortfolio.modelPortfolio ==
              val.modelPortfolio
          ) {
            const series = []
            const labels = []
            data.modelPortfolio = val
            Object.entries(val.data).forEach(([, obj]) => {
              series.push(obj.y)
              labels.push(obj.x)
            })
            data.series = series
            data.chartOptions = {
              colors: [
                '#BBBBBB',
                '#828282',
                '#55677D',
                '#FFC46D',
                '#007EB9',
                '#FFAC31',
                '#31465F',
                '#FF9900',
                '#B71C1C'
              ],
              labels: labels,
              dataLabels: {
                enabled: false
              },
              yaxis: {
                type: 'numeric',
                labels: {
                  formatter: function(value) {
                    return value + ' %'
                  }
                }
              },
              legend: {
                show: false,
                showForNullSeries: true,
                position: 'right',
                formatter: function(seriesName, opts) {
                  return opts.w.globals.series[opts.seriesIndex] <= 9
                    ? [
                        '  ',
                        opts.w.globals.series[opts.seriesIndex],
                        ' % ',
                        seriesName
                      ]
                    : [
                        opts.w.globals.series[opts.seriesIndex],
                        ' %  ',
                        seriesName
                      ]
                },
                offsetY: 0,
                offsetX: -20
              },
              tooltip: {
                enabled: true
              }
            }
          }
        }
      )

      return data
    },

    ...mapState(['clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
