<template>
  <div>
    <v-card v-if="clientIncomeSources.length > 0" outlined>
      <v-card-title class="overline"> Income Statement</v-card-title>
      <v-row class="mt-n8">
        <v-col cols="4" class="text-left">
          <v-btn
            text
            retain-focus-on-click
            color="success"
            :to="{ name: 'clientLedger', params: { tabs: 'income' } }"
            class="subtitle-1 font-weight-medium mt-3"
            >Income
          </v-btn>
        </v-col>
        <v-col cols="8" class="text-right">
          <v-card-text class="subtitle-1"
            >{{
              clientIncomeSourcesSum.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })
            }}
            {{ currencyApp }} p.a.
          </v-card-text>
        </v-col>
      </v-row>

      <v-row class="text-left mt-n3">
        <v-col cols="4">
          <v-btn
            text
            retain-focus-on-click
            color="success"
            :to="{ name: 'clientLedger', params: { tabs: 'expenses' } }"
            class="subtitle-1 font-weight-medium mt-n13"
            >Expenses
          </v-btn>
        </v-col>
        <v-col cols="8" class="text-right">
          <v-card-text class="subtitle-1 mt-n11"
            >-
            {{
              clientSpendingStreamsSum.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })
            }}
            {{ currencyApp }} p.a.
          </v-card-text>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-divider class="mx-4 mt-n5"></v-divider>
        </v-col>
        <v-col cols="6" class="text-left mt-n10">
          <v-card-text class="subtitle-1">
            Surplus/Deficit
          </v-card-text>
        </v-col>
        <v-col cols="6" class="text-right mt-n10">
          <v-card-text class="subtitle-1">
            {{
              (
                clientIncomeSourcesSum - clientSpendingStreamsSum
              ).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })
            }}
            {{ currencyApp }} p.a.
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'

export default {
  name: 'clientDashboardIncomeStatement',

  components: {},

  data() {
    return {}
  },

  methods: {},

  computed: {
    ...mapState(['clientLedger']),

    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
